import "./styles.css";
import "sanitize.css";
import React, { createContext, useState, useEffect, footer } from "react";
import { Helmet } from "react-helmet";
import ReactSwitch from "react-switch";
import Iframe from "react-iframe";
import { SocialIcon } from "react-social-icons";
import FadeIn from "react-fade-in";
import { CFooter } from "@coreui/react";
// import WaterWave from "react-water-wave";

export const ThemeContext = createContext("dark");

function App() {
  const [theme, setTheme] = useState("light");

  const placeholderText = [
    "nɐɹᴉʞʞ",
    "2",
    "nɐɹᴉʞʞ"
    // "im happy for you bro",
    // "nah fr",
    // "idc bout nun bro i be coolin",
    // "go away !!!",
    // "fuck you",
    // "jk didn't mean it <3",
    // "ive been watching rent-a-girlfriend",
    // "its horrible but i like it",
    // "...",
    // "what if i pulled up to the function in squirrel hill, pgh in my draingang tee",
    // "bouta griddy on tha job",
    // "ssense package comes monday ^_^",
    // "sg lily - let me go",
    // "roommate keeps sending me songs I've already heard before but won't listen to my shit",
    // "prick",
    // "i just hit 2k hours on cs",
    // "（っ＾▿＾）",
    // "itll be wraps when she finds out im a moderator for psp1g's twitch channel",
    // "(╥﹏╥)",
    // "im going to sleep",
    // "bye",
    // "gn",
    // "┬┴┬┴┤(･_ ├┬┴┬┴",
    // "http://csgo.exchange/id/76561198127882341"
  ];

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  const Cycle = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
      const timer = () => {
        setIndex((prevIndex) => {
          if (prevIndex === placeholderText.length - 1) {
            return 0;
          }
          return prevIndex + 1;
        });
      };
      setInterval(timer, 1500);

      //cleanup function in order clear the interval timer
      //when the component unmounts
      return () => {
        clearInterval(timer);
      };
    }, []);

    return <p>{placeholderText[index]}</p>;
  };

  return (
    <>
      <Helmet>
        <title>k .</title>
      </Helmet>

      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <div className="App" id={theme}>
          {/* <WaterWave
            style={{ width: "100%", height: "100%", backgroundSize: "cover" }}
          >
            {(methods) => ( */}
          <div className="container">
            <FadeIn delay="400">
              <h1>ｋｉｒａｎ . wtf</h1>
              <h4>{Cycle()}</h4>
              <div className="switch">
                <label> {theme === "light" ? "ʕ´• ᴥ•̥`ʔ" : "(っ´ω｀c)"} </label>
                <ReactSwitch
                  onChange={toggleTheme}
                  checked={theme === "dark"}
                  onColor="#888"
                  offColor="#000"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  className="swt"
                />
              </div>
              <div className="postdate">
                <h5>Oct 18 23</h5>
              </div>
              <div className="post">
                <div className="posttext">
                  <p>
                    The smartest people in the world don’t want anything to do
                    with you. They can smell your insecurity like it seeps from
                    your pits. They're either too autistic or can’t be bothered
                    to be concerned with appearance. Their is skin pocked or
                    bubbling like the cgi lava at the end of the second star
                    wars movie and framing their beady black eyes are dusty
                    lenses covered in greasy fingerprints. Overgrown toenails
                    curl over their destroyed sandals accompanied by some fungus
                    if they’re truly brilliant.
                  </p>
                  <p>
                    but who the fuck are you to judge them for wearing the same
                    high school sweatshirt to class for the last 3 years. they
                    could walk circles around you in any computationally or
                    logically complex problem and then probably beat you in 1v1
                    on league with your main champion. and they play the piano
                    like the ugly guys on youtube.
                  </p>
                  <p>
                    it’s a little soul crushing to know that some of that genius
                    always gets snatched by a quant firm early. infatuated by
                    the quarter million salary and being able to tell their
                    lunch table that they passed their review for Jane Street,
                    the prospects are just too ripe for a kid like that.
                  </p>
                  <p>
                    it’s the same feeling as finding out a beautiful girl in
                    high school is seeing an older guy. knowing that perfect
                    pussy is getting slammed by a guy with a base model audi who
                    probably sucks worse than you. you see her eyes widen when
                    she tells you about him.
                  </p>
                  <p>at least the geniuses are paid to get fucked.</p>
                </div>
                <div className="spotifyembed">
                  <Iframe
                    url="https://open.spotify.com/embed/track/22yDGXxkt3e7GUobLmGkpF?utm_source=generator"
                    display="initial"
                    height="80"
                    position="relative"
                  />
                </div>

                <p>--kiran // gpg yyy_K </p>
              </div>
            </FadeIn>

            <footer>
              <div className="twt">
                <SocialIcon
                  url="https://twitter.com/kkir4n"
                  fgColor={theme === "light" ? "#000" : "#fff"}
                  bgColor={theme === "light" ? "#fff" : "#000"}
                />
              </div>
            </footer>
          </div>
        </div>
      </ThemeContext.Provider>
    </>
  );
}

export default App;
